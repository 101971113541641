import './App.css';
import * as XLSX from 'xlsx';
import {useState} from "react";
import ReactDataSheet from "react-datasheet";
import 'react-datasheet/lib/react-datasheet.css';
import {Button, Col, Drawer, Form, Row, Space,Input} from "antd";
const { TextArea } = Input;


function App() {

  const [excelData, setExcelData] = useState(null);
  const [reactDataSheetData, setReactDataSheetData] = useState([]);
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState({
    sourceJson:"",
    resultJson:""
  });
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };

  const submitForm=()=>{
    form.validateFields().then(()=>{
      form.submit()
    })

  }

  const onFinish = (values) => {
    setExcelData([])
    setReactDataSheetData([])
    const resJson = JSON.parse(values.resultJson)

    const rdData = []
    const colwidthConfig = [150, 100, 150, 80, 80, 300, 300, 150, 150]
    const jsonData = excelData
    jsonData.forEach((jsonArrayitem, idx) => {
      if(idx>0){
        jsonArrayitem[6] = resJson[idx-1]
      }
    })
    jsonData.forEach((jsonArrayitem, idx) => {
      rdData[idx] = jsonArrayitem.map((innerItem, innerIdx) => {
        const cellObj = {value: innerItem}
        if (idx === 0) {
          cellObj.readOnly = true
        }
        cellObj.width = colwidthConfig[innerIdx]
        cellObj.overflow = "wrap"
        if (idx === 8) {
          cellObj.overflow = "clip"
        }
        return cellObj
      })
    })



    setReactDataSheetData(rdData)
    setExcelData(jsonData)


    setOpen(false);
  };
  // 导入Excel文件
  const importExcel = (event) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, {type: 'array'});
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {header: 1});
      if (jsonData[0].length === 8) {
        jsonData[0].splice(6, 0, "反馈内容翻译")
        // const transData = []
        // jsonData.forEach((jsonArrayitem, idx) => {
        //   if (idx !== 0) {
        //     transData[idx] = jsonArrayitem[5]
        //   }
        // })
        // console.log(JSON.stringify(transData))
        // await translate(JSON.stringify(transData), "auto", "zh-CN").then((res) => {
        //   const transDataRes = JSON.parse(res)
        //   console.log(transDataRes)
        //   jsonData.forEach((jsonArrayitem, idx) => {
        //     if (idx !== 0) {
        //       jsonArrayitem.slice(6,0,transDataRes[idx])
        //     }
        //   })
        // })

        //请自动识别以下json包含的多国语言并翻译成中文，保留json的格式
        //请自动识别{{input}}的json包含的多国语言并翻译成中文，null不用翻译，不需要加换行符，结果保留原json的数组格式，输出的结果和原本json格式一致
        const tData = []
        jsonData.forEach((jsonArrayitem, idx) => {
          if (idx > 0) {
            tData[idx-1] = jsonArrayitem[5]
            jsonData[idx].splice(6, 0, "")
          }
        })
        setFormValues({
          sourceJson:JSON.stringify(tData),
          resultJson:""
        })
        console.log(JSON.stringify(tData))
        setOpen(true)
        //console.log(JSON.stringify(transData))
        //alert(JSON.stringify(transData))


      }
      const rdData = []
      const colwidthConfig = [150, 100, 150, 80, 80, 300, 300, 150, 150]
      jsonData.forEach((jsonArrayitem, idx) => {
        rdData[idx] = jsonArrayitem.map((innerItem, innerIdx) => {
          const cellObj = {value: innerItem}
          if (idx === 0) {
            cellObj.readOnly = true
          }
          cellObj.width = colwidthConfig[innerIdx]
          cellObj.overflow = "wrap"
          if (idx === 8) {
            cellObj.overflow = "clip"
          }
          return cellObj
        })
      })
      setReactDataSheetData(rdData)
      setExcelData(jsonData)
    };
    reader.readAsArrayBuffer(event.target.files[0]);
  };

  // 导出Excel文件
  const exportExcel = () => {
    if (excelData) {
      const worksheet = XLSX.utils.json_to_sheet(excelData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      XLSX.writeFile(workbook, 'output.xlsx');
    }
  };

  const valueRenderer =(cell, i, j)=> {
    return cell.value
  }

  return (
      <>
        <div style={{margin:'20px 20px'}}>
          <div style={{marginBottom:'20px'}}>
            <input type="file" onChange={importExcel}/>
            <button onClick={exportExcel}>导出Excel</button>
          </div>


          {excelData && (
              // <pre>{JSON.stringify(excelData, null, 2)}</pre>
              <ReactDataSheet data={reactDataSheetData} valueRenderer={valueRenderer}/>
          )}
        </div>
        <Drawer
            title="翻译处理"
            maskClosable={false}
            closeIcon={null}
            width={720}
            open={open}
            styles={{
              body: {
                paddingBottom: 80,
              },
            }}
            extra={
              <Space>
                <Button type="primary" onClick={submitForm}>
                  执行翻译结果
                </Button>
              </Space>
            }
        >
          <Form layout="vertical" onFinish={onFinish} form={form} initialValues={formValues}>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                    name="sourceJson"
                    label="复制黏贴到飞书机器人进行翻译"
                    rules={[
                      {
                        required: false,
                        message: '',
                      },
                    ]}
                >
                  <TextArea rows={8} placeholder="复制黏贴到飞书机器人进行翻译"/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                    name="resultJson"
                    label="翻译结果Json"
                    rules={[
                      {
                        required: true,
                        message: '请把飞书机器人翻译的json结果黏贴到本输入域',
                      },
                    ]}
                >
                  <TextArea rows={8} placeholder="请把飞书机器人翻译的json结果黏贴到本输入域"/>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Drawer>
      </>

  );
}

export default App;
